import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { Button } from 'antd';
import userManager from "../../utils/userManager";

import { icons }  from '../../assets/Images'
import { failure } from '../../reduxs/auth-redux/reducer';
import './styles.scss';

class LoginForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    const { reloadLogin, history, user } = this.props;
    const isLogin = user && user.access_token;
    if (isLogin) {
      history.goBack()
    }
    reloadLogin();
  }

  render() {
    const { user, loading } = this.props;
    const isLogin = user && user.access_token;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    let redirectForm = from;
    const url = new URL(window.location.href);
    if(url && url.searchParams) {
      let next = url.searchParams.get('next');
      if(next) redirectForm = { pathname: next };
    }
    if (isLogin) {
      return <Redirect to={redirectForm} />;
    }
    return (
      <div className="home mt-5">
        <Helmet titleTemplate="%s - Report" defaultTitle="Đăng nhập hệ thống">
          <meta name="description" content="PCS - System" />
        </Helmet>
        <div className="form-login">
          <div className="mr-b-50 pd-15">
            <article id="content" className="row box-login mr-0">
              <div className="col-md-5 tab-logo hidden-md hidden-lg">
                <div className="image-login">
                  <img src={icons.common.logopcs} alt=""/>
                </div>
              </div>
              <div className="col-md-7 tab-login">
                  <h3 className="text-center">Đăng nhập</h3>
                  <p className="text-center">Click nút dưới đây để đăng nhập tài khoản của bạn</p>
                  <br/>
                  <br/>
                  <p className="text-center">
                    <Button type="primary" loading={loading} onClick={() => userManager.signinRedirect()}>
                      <i className="fa fa-sign-in" />&ensp;Đăng nhập vào hệ thống
                    </Button>
                  </p>
              </div>

              <div className="col-md-5 tab-banner hidden-xs hidden-sm">
                <div className="image-login">
                  <img src={icons.common.logopcs} alt=""/>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    )
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    reloadLogin: () => dispatch(failure()),
  };
}

const mapStateToProps = createStructuredSelector({
  user: (state) => state.oidc && state.oidc.user,
  loading: (state) => state.oidc && state.oidc.isLoadingUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)